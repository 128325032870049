<template>
  <div class="skeleton skeleton-loading"></div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{ isSelected?: boolean; min?: number; max?: number }>(), { min: 32, max: 128 });

const width = randomWidth(props.min, props.max);
</script>

<style scoped lang="scss">
.skeleton {
  height: 16px;
  border-radius: 100px;
  width: v-bind(width);
}
</style>
